@import '@/components/commonStyle/common.scss';

.root {
	background-color: #fff;
	padding-bottom: 10px;
	.title{
		padding: 15px 10px;
		font-size: 18px;
		border-bottom: 1px solid #F1F1F1;
		padding-left: 20px;
	}
	.formWrapper{
		padding: 20px;
	}
}
.closeIcon{
	position: absolute;
	right: 0px;
	top: 4px;
}