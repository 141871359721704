@import '@/components/commonStyle/common.scss';

.popTitle{
  font-size: 14px;
  color: #525252;
  padding-right: 22px;
}

.popYesBtn{
  display: inline-block;
  width: 44px;
  height: 20px;
  & > img{
    width: 12px;
    height: 10px;
    margin-right: 5px;
    margin-right: 4px;
    transform: translateY(-1px);
  }
}

.popNoBtn{
  display: inline-block;
  width: 44px;
  height: 20px;
  & > img{
    width: 10px;
    height: 10px;
    margin-right: 4px;
    transform: translateY(-1px);
  }
}