@import '~antd/dist/antd.min.css';

a{
  color: #303133;
}

p{
  margin: 0;
}

a:hover{
  color: #303133;
  text-decoration: underline;
}

.a-link{
  color: #0048FF;
  text-decoration: none;
}
.a-link:hover{
  color: #0048FF;
  text-decoration: none;
}

.ant-row.ant-form-item {
  margin: 0;
}

.anticon svg,.ant-steps-icon svg{
  vertical-align: baseline;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"]{
  -moz-appearance: textfield !important;
}

.biz-license-tooltip .ant-tooltip-inner{
  font-size: 14px;
  color: #525252;
  padding: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 111111s;
  transition: color 11111s ease-out, background-color 111111s ease-out;
}

.ant-input-suffix{
  color: #686D70;
}
.menu-content{
  color: #363636;
}
.menu-content:hover{
  color: #0060FF;
}
.menu-content img{
  width: 14px;
  margin-right: 12px;
}
.sign-out-menu{
  text-align: center;
  border-top: 1px solid #F4F4F4;
  color: #959595;
  padding-top: 5px;
  font-size: 14px;
}
textarea.ant-input, .ant-modal-confirm .ant-modal-content{
  border-radius: 6px;
}

.ant-popover-inner-content > .ant-popover-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-popover-inner{
  border-radius: 7px;
  box-shadow: 0px 0px 18px 0px rgba(212, 212, 212, 0.79);
}

.ant-popover-buttons > .ant-btn:not(.ant-btn-primary){
  border: 1px solid #A6A6A6;
  border-radius: 12px;
  margin-left: 10px;
  min-width: 60px;
  min-height: 26px;
  transition: all 0.3s;
}

.ant-popover-buttons > .ant-btn-primary{
  min-width: 60px;
  min-height: 26px;
  border: 1px solid #13C2C2;
  border-radius: 12px;
  background: #fff;
  color: #13C2C2;
  margin-left: 0;
  order: -1;
  transition: all 0.3s;
}
.ant-popover-buttons > .ant-btn-primary:hover {
  opacity: 0.6;
}
.ant-popover-buttons > .ant-btn:not(.ant-btn-primary):hover{
  opacity: 0.6;
  color: rgba(0,0,0,.85);
}

.ant-cascader-picker, .ant-input{
  color: #000;
}

/* 给spin加上className="default-spin"  */

.default-spin .ant-spin-dot.ant-spin-dot-spin {
  display: inline-block;
  position: absolute;
  margin-left: -40px !important;
  margin-top: -66px !important;
  width: 80px;
  height: 80px;
  animation: none;
  transform: none;

}
.default-spin .ant-spin-dot-spin > .ant-spin-dot-item {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 12px;
  background: #38a8ff;
  border-radius: unset;
  transform: none;
  transform-origin: unset;
  opacity: unset;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.default-spin .ant-spin-dot-spin .ant-spin-dot-item:nth-child(1) {
  left: 10px;
  animation-delay: -0.24s;
}
.default-spin .ant-spin-dot-spin .ant-spin-dot-item:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.default-spin .ant-spin-dot-spin .ant-spin-dot-item:nth-child(3) {
  left: 54px;
  animation-delay: 0;
}
.default-spin .ant-spin-dot-spin .ant-spin-dot-item:nth-child(4) {
  display: none;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

/* 滚动条 */
::-webkit-scrollbar-track-piece {
	background-color:#f8f8f8;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

::-webkit-scrollbar {
	width:9px;
}

::-webkit-scrollbar-thumb {
	background-color:#dddddd;
	background-clip:padding-box;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

::-webkit-scrollbar-thumb:hover {
	background-color:#bbb;
}
.ant-form-item-label>label{
  color: #131519 !important;
}

.edit-page{
  padding: 20px;
  background-color: #fff;
}
.ant-tabs-tab:hover{
  color: #5767FA !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #5767FA !important;
}
.ant-tabs-ink-bar{
  background-color: #5767FA !important;
}

.ant-input, .ant-select-selector, .ant-picker{
  border-radius: 6px !important;
}

.forgetPassword .ant-btn-primary{
  background-color: #5767FA;
  border-color: #5767FA;
}

.forgetPassword .ant-btn-primary:hover{
  opacity: 0.8;
}

.ant-spin-nested-loading .ant-spin-dot-item{
  background-color: #5767fa !important;
}
.ant-spin-nested-loading .ant-spin-text{
  color: #5767fa !important;
}

.ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link{
  color: #5767fa !important;
  border-color: #5767fa !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn:hover{
  border-color: #5767FA;
  color: #5767FA;

}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary,
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary:hover{
  background-color: #5767FA;
  border-color: #5767FA;
  color: #fff;
}