@import '@/components/commonStyle/common.scss';

.container{
  overflow: auto;
  min-height: 100vh;
  position: relative;
  .sidedHead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F5F5F5;
    padding: 10px 19px 15px;
    border-right: 1px solid #F5F5F5;
    .sidedHeadL{
      .logo{
        // width: 48px;
        height: 55px;
      }
    }
    .collapsedBox{
      :global{
        .anticon{
          cursor: pointer;
        }
      }
    }
  }
  .logo{
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    // position: fixed;
    // z-index: 1;
    // width: 220px;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .indexHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2761DD;
    // position: fixed;
    // z-index: 1;
    // width: calc(100vw - 220px);
    // left: 220px;
    .userInfo{
      display: flex;
      align-items: center;
      .collapsedBox{
        margin-right: 16px;
        border: 1px solid #2761DD;
        height: 30px;
        width: 30px;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.5s;
        &:hover{
          color: #fff;
          background: #2761DD;
        }
      }
      .siteName{
        margin-right: 1em;
        font-weight: 700;
        font-size: 20px;
      }
    }
    .headerRight{
      display: flex;
      align-items: center;
      margin-left: auto;
      color: #181B23;
      .admin{
        margin-right: 10px;
      }
      .myDropdown{
        // margin-left: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .nameBox{
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #181B23;
          // margin-left: 6px;
          .name{
            border-bottom: 1px solid #ddd;
            margin: 0 6px;
          }
        }
      }
    }
  }
  .breadcrumbBox{
    padding: 15px;
    :global{
      // .ant-breadcrumb > span {
      //   &:not(:first-child){
      //     .ant-breadcrumb-link{
      //       cursor: pointer;
      //       &:hover{
      //         color: $mainFontColor;
      //       }
      //     }
      //   }
      // }
      .ant-breadcrumb{
        font-size: 14px;
        color: #131519;
      }
    }
  }
  .pageContent{
    padding: 15px;
    height: calc(100vh - 85px);
    overflow-y: auto;
  }
  :global{
    .ant-layout{
      background-color: #F1F3F6;
    }
    .ant-layout-header{
      padding-left: 0;
      padding-right: 30px;
      background-color: #fff;
      height: 80px;
    }
    .ant-layout-sider{
      background-color: #fff;
    }
    .ant-menu-submenu{
      border-bottom: 1px solid #F5F5F5;
    }
    .ant-menu-submenu-selected{
      color: #5767FA;
    }
    .ant-menu-submenu-title{
      color: #6D7581;
      -moz-user-select:none;/*火狐*/
      -webkit-user-select:none;/*webkit浏览器*/
      -ms-user-select:none;/*IE10*/
      -khtml-user-select:none;/*早期浏览器*/
      user-select:none; 
      &:hover{
        color: #5767FA;
        .ant-menu-submenu-arrow{
          color: #5767FA !important;
        }
      }
    }
    .ant-menu-sub.ant-menu-inline>.ant-menu-item{
      padding-left: 20px !important;
      margin-left: 28px;
      height: 30px;
      line-height: 30px;
      margin-right: 20px;
      border-radius: 15px;
    }
    .ant-menu-sub.ant-menu-inline{
      background: #fff;
      // padding-bottom: 8px;
    }
    .ant-menu-inline .ant-menu-item{
      width: auto;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
      background-color: #F7F7FF;
      color: #5767FA;
    }
    .ant-menu-inline .ant-menu-item-selected:after{
      display: none;
    }
    .ant-menu-item{
      color: #131519;
      &:hover{
        background-color: #F7F7FF;
        color: #5767FA;
      }
    }
  }
}

.topMenuIcon{
  max-width: 14px;
  max-height: 14px;
  object-fit: contain;
}