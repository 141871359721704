@import '@/components/commonStyle/common.scss';

.filterBox{
	padding: 30px 20px 15px;
	border-radius: 6px;
	margin-bottom: 15px;
	background-color: #fff;
	.btnWarp{
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.headBtnBox{
			margin-bottom: 16px;
		}
	}
	.filterRow{
		:global{
			.ant-col{
				margin-bottom: 8px;
			}
		}
	}
	:global{
		// .ant-col{
		// 	// margin-bottom: 8px;
		// 	label, .ant-input, .ant-select, .ant-btn{
		// 		font-size: 12px;
		// 	}
		// }
		.ant-input, .ant-picker{
			padding: 6px 11px;
		}
	}
}