.disabledBtn {
  border-color: none !important;
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  display: flex;
  align-items: center;

  :global {

    .ant-btn:active,
    .ant-btn:hover,
    .ant-btn:focus {
      border-color: #d9d9d9 !important;
      background: #fff !important;
      color: #333 !important;
    }
  }
}

.extra {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 20px;
  margin-left: 8px;
}

.bgWhite {
  background: #fff;
  display: flex;
  align-items: center;
}

.loadingBox {
  text-align: center;
  margin-top: 5px;

  .loadingText {
    margin-top: 10px;
  }
}