

@mixin media-breakpoint-up($size) {
  @if $size == sm {
    @media (min-width: 576px) {
      @content
    }
  };
  @if $size == md {
    @media (min-width: 768px) {
      @content
    }
  };
  @if $size == lg {
    @media (min-width: 992px) {
      @content
    }
  };
  @if $size == xl {
    @media (min-width: 1200px) {
      @content
    }
  }
};

@mixin media-breakpoint-down($size) {
  @if $size == sm {
    @media (max-width: 576px) {
      @content
    }
  };
  @if $size == md {
    @media (max-width: 768px) {
      @content
    }
  };
  @if $size == lg {
    @media (max-width: 992px) {
      @content
    }
  };
  @if $size == xl {
    @media (max-width: 1200px) {
      @content
    }
  }
}

$autoFontColor: #04141E;
$desFontColor: #686D70;
$mainFontColor: #209DF3;
$darkFontColor: #898989;
$caseTitleColor: #2463EA;
$defaultBgColor: #FAFAFA;

$sectionSize: 40px;
$autoSize: 16px;
$titleSize: 20px;
$smallSize: 14px;

@mixin bottom-text-line($height) {
  &::after{
    content: '';
    display: inline-block;
    background: #209DF3;
    height: $height;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }
}

@mixin steps-form-root {
  display: flex;
  width: 85%;
  margin: 20px auto;
  max-width: 1800px;
  background-color: #fff;
  border: 1px solid #F8F8F8;
}

@mixin form-title {
  color: $autoFontColor;
  font-size: 22px;
  font-weight: bold;
}

@mixin form-subtitle {
  color: $desFontColor;
  font-size: $smallSize;
  margin-top: 8px;
  line-height: 24px;
}

.saveBtn{
  width: 86px;
  height: 32px;
  background: #5862FF;
  border-radius: 16px;
  text-align: center;
  line-height: 31px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  padding: 0;
  border-color: #5862FF;
  &:hover{
    background: #5862FF;
    border-color: #5862FF;
    opacity: 0.6;
  }
  &:focus{
    background: #5862FF;
    border-color: #5862FF;
  }
}

.closeBtn{
  width: 86px;
  height: 32px;
  background: #fff;
  border: 1px solid #CDCDCD;
  border-radius: 16px;
  text-align: center;
  line-height: 31px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8A8A8A;
  padding: 0;
  &:hover{
    background: #fff;
    border-color: #CDCDCD;
    color: #8A8A8A;
    opacity: 0.6;
  }
}

.formTable{
  :global{
    .ant-table{
      // box-shadow: 0px 0px 13px 0px rgba(222, 222, 222, 0.27);
			// padding: 10px 20px 0;
      .ant-table-container{
        .ant-table-content{
          .ant-table-thead{
            .ant-table-cell{
              background-color: #FAFAFA;
              // padding: 0 8px 8px 8px;
              padding: 16px 10px;
              font-size: 14px;
              color: $desFontColor;
            }
          }
          .ant-table-tbody{
            .ant-table-row{
              .ant-input, .ant-input-affix-wrapper, .ant-picker{
                border: none;
              }
              .ant-select{
                .ant-select-selector{
                  border: unset;
                }
                .ant-select-arrow{
                  display: none;
                }
              }
              .ant-table-cell{
                // border: unset;
                padding: 16px 10px;
                // font-size: 13px;
                // font-weight: bold;
                color: $autoFontColor;
                .ant-select-selector, .ant-input, .ant-input-affix-wrapper{
                  padding-left: 0;
                }
              }
              & + .ant-table-row{
                .ant-table-cell{
                  // padding-top: 6px;
                }
              }
              // &:last-child{
              //   .ant-table-cell{
              //     border-bottom: none;
              //   }
              // }
            }
            .ant-table-placeholder{
              .ant-table-cell{
                border-bottom: none;
              }
            }
          }
          // .ant-table-cell {
          //   border-bottom-style: dashed;
          // }
        }
      }
    }
  }
}

// 动画

@keyframes leftMove {
  0%{
    transform: translateX(200px);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes topMove {
  0%{
    transform: translateY(-200px);
    opacity: 0;
  }
  20%{
    transform: translateY(0);
    opacity: 1;
  }
  40%{
    transform: translateY(-30px);
    opacity: 1;
  }
  60%{
    transform: translateY(0);
    opacity: 1;
  }
  80%{
    transform: translateY(-15px);
    opacity: 1;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes bottomMove {
  0%{
    transform: translateY(300px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes opacityMove {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes light{
  0%{
    top: -100px;
    left: -350px;
    opacity: 0;
  }
  100%{
    opacity: 1;
    top: -300px;
    left: 1200px;
  }
}

@keyframes shake {
  12.5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(20deg);
  }
  37.5% {
    transform: rotate(30deg);
  }
  67.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  87.5% {
    transform: rotate(-30deg);
  }
  0%,
  50%,
  100% {
    transform: rotate(0);
  }
}


.btnAnimation{
  overflow: hidden;
  position: relative;
  &:after{
    content: '';
    width: 20px;
    height: 400px;
    background: linear-gradient(90deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 50%,rgba(255,255,255,0) 100%);
    transform: rotate(45deg);
    position: absolute;
    top: -100px;
    left: -200px;
    opacity: 0;
    animation: btnLight 3s infinite;
  }
}

@keyframes btnLight{
  0%{
    top: -150px;
    left: -200px;
    opacity: 0;
  }
  100%{
    opacity: 1;
    top: -50px;
    left: 600px;
  }
}
