$timeFunction: ease-in-out;
$duration: 3s;
$delay: 0s;

.loadingBox {
  text-align: center;
  margin-top: 5px;

  .loadingText {
    margin-top: 10px;
  }
}

.container {
  width: 56px;
  height: 56px;

  .box1,
  .box2,
  .box3 {
    border: 5px solid #aacaff;
    box-sizing: border-box;
    position: absolute;
    display: block;
  }

  .box1 {
    width: 56px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
    animation: anime1 $duration $delay forwards $timeFunction infinite;
  }

  .box2 {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
    animation: anime2 $duration $delay forwards $timeFunction infinite;
  }

  .box3 {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
    animation: anime3 $duration $delay forwards $timeFunction infinite;
  }
}

@keyframes anime1 {
  0% {
    width: 56px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  12.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  25% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  37.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  50% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  62.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }

  75% {
    width: 24px;
    height: 56px;
    margin-top: 0px;
    margin-left: 0px;
  }

  87.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  100% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }
}

@keyframes anime2 {
  0% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  12.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  25% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  37.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  50% {
    width: 56px;
    height: 24px;
    margin-top: 0px;
    margin-left: 0px;
  }

  62.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }

  75% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }

  87.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }

  100% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }
}

@keyframes anime3 {
  0% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }

  12.5% {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-left: 32px;
  }

  25% {
    width: 24px;
    height: 56px;
    margin-top: 0px;
    margin-left: 32px;
  }

  37.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 32px;
  }

  50% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 32px;
  }

  62.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 32px;
  }

  75% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 32px;
  }

  87.5% {
    width: 24px;
    height: 24px;
    margin-top: 32px;
    margin-left: 32px;
  }

  100% {
    width: 56px;
    height: 24px;
    margin-top: 32px;
    margin-left: 0px;
  }
}