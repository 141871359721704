@import '@/components/commonStyle/common.scss';

.footerBtnWrapper{
  display: flex;
  align-items: center;
  margin: 24px 0;
  .footerBtn{
    &:first-child{
      margin-left: auto;
    }
    & + .footerBtn{
      margin-left: 12px;
    }
  }
  .arrowBtn{
    padding: 0 25px;
    background: #0060FF;
    border-radius: 22px;
    // font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    // line-height: 44px;
    text-align: center;
    cursor: pointer;
    position: relative;
    .arrow{
      margin-left: 10px;
      position: relative;
      top: -4px;
      .arrowIcon{
        position: absolute;
        transition: all 0.15s linear;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;
        font-size: 14px;
        z-index: 2;
      }
      .line{
        position: absolute;
        width: 10px;
        height: 1px;
        background-color: #fff;
        left: 3px;
        top: 50%;
        opacity: 0;
        transition: all 0.2s;
        transform: translateY(-50%);
      }
    }
    &:hover{
      opacity: 0.6;
      .arrow{
        .arrowIcon{
          left: 3px;
        }
        .line{
          opacity: 1;
          
        }
      }
    }
  }
  .leftBtn{
    background: #fff;
    border: 1px solid #0060FF;
    color: #0060FF;
    display: flex;
    align-items: center;
    .arrow{
      order: -1;
      transform: scale(-1) translate(6px, -4px);
      .arrowIcon{
        color: #0060FF;
      }
      .line{
        background-color: #0060FF;
      }
    }
  }
}
