@import '@/components/commonStyle/common.scss';

.root {
  .returnBtn {
    background-color: #5767FA;
    border-radius: 16px;
    font-size: 14px;
    color: #fff;
    border: none;
    transition: all 0.3s;
  }
}