@import '@/components/commonStyle/common.scss';

.root {
	.pageHead{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		.pageTitle{
			font-size: 18px;
			font-weight: bold;
		}
	}
	.btnItem{
		font-size: 12px;
		height: 28px;
		&:first-child{
			margin-left: 0;
		}
	}
	.pageHeadWarp{
		display: flex;
		justify-content: flex-end;
	}
	.tableWarp{
		padding: 25px 20px;
		border-radius: 6px;
		background-color: #fff;
	}
	.pageBox{
		margin-top: 20px;
		text-align: right;
		:global{
			.ant-pagination-item{
				border: none;
				color: #9F9F9F;
				&:hover{
					background: #5767FA;
					a{
						color: #fff;
					}
				}
			}
			.ant-pagination-item-active{
				background: #5767FA;
				a{
					color: #fff;
				}
			}
			.ant-select:not(.ant-select-customize-input) .ant-select-selector{
				border: none;
				background-color: #F4F6FC;
			}
		}
	}
}