@import '@/components/commonStyle/common.scss';

.rowItemsWrapper{
  display: flex;
  // align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  // max-width: 1200px;
  // margin: 0 auto;
  // .formWrapper{
  //   max-width: 600px;
  //   flex-grow: 1;
  //   padding-bottom: 10px;
  //   & > div:first-child{
  //     label{
  //       min-width: 120px;
  //     }
  //   }
  //   & + .formWrapper{
  //     margin-left: 20px;
  //     width: 460px;
  //     flex-grow: 0;
  //   }
  // }
  .halfItem{
    margin-right: 20px;
    width: calc((100% - 20px) / 2);
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  .rowItem{
    width: 100%;
  }
  :global{
    .ant-form-item-label{
      padding-bottom: 0 !important;
      label{
        color: $desFontColor;
      }
    }
  }
}
.title{
  // text-align: center;
  margin-bottom: 12px;
  @include form-title;
}
.subtitle{
  margin-bottom: 25px;
  @include form-subtitle;
}

.description{
  font-size: 14px;
  color: #686D70;
  margin-bottom: 23px;
}
