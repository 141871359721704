
.chart-container{
  width: 100%;
  height: 100%;
}

.chart-canvas{
  width: 100%;
  height: 100%;
  position: relative;
}