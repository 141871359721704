@import '@/components/commonStyle/common.scss';

.login-page{
  position: relative;
  // width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #F9F9F9;
  .login-page-r{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .login-bg1{
      width: 300px;
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
}
.login-container{
  .form-head{
    margin-bottom: 55px;
    text-align: center;
    .sub-title{
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #5F6976;
      line-height: 24px;
      margin-bottom: 28px;
    }
    .form-title{
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0B0D0F;
      line-height: 24px;
    }
  }
  .login-form{
    padding-bottom: 20px;
    width: 546px;
    height: 457px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 72px 45px;
    // box-shadow: 0px 0px 18px 0px rgba(212, 212, 212, 0.79);
    .ant-form-item-label{
      width: 60px;
      // label{
      //   color: #fff;
      // }
    }
    .send-code-wrap{
      .ant-form-item{
        flex-grow: 1;
      }
    }
    .ant-form-item-control-input-content{
      .ant-input-lg{
        padding: 7.6px 11px;
        height: 60px;
        font-size: 18px;
        border: none;
        background-color: #F9F9F9;
      }
      .label-icon{
        width: 18px;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      .send-code-wrap{
        align-items: flex-end;
      }
      .ant-form-item-explain{
        position: absolute;
        bottom: -22px;
      }
    }
    .ant-row.ant-form-item{
      border: none;
      padding: 5px 0;
      .ant-input-prefix{
        margin-right: 12.5px;
      }
      .ant-input-affix-wrapper-lg{
        padding: 0 20px;
        border: none;
        background-color: #F9F9F9;
        border-radius: 8px;
      }
    }
    
    & > .ant-form-item:last-child {
      margin: 0;
    }
    
    input {
      vertical-align: middle;
    }

    .select-company{
      width: 100%;
      .ant-select-selector{
        height: 46px;
        border-radius: 23px;
        .ant-select-selection-item, .ant-select-selection-search, .ant-select-selection-placeholder{
          line-height: 44px;
          padding-left: 11px;
          font-weight: 400;
          color: #bfbfbf;
        }
      }
    }
    
    .user-setting-item{
      margin-bottom: 6px;
    }
    
    .user-setting{
      display: flex;
      justify-content: space-between;
    }
    
    .login-form-button{
      // width: 100%;
      color: #fff;
      background-color: #2463EA;
      border-color: #2463EA;
    }
    .ant-row.ant-form-item.sign-in {
      border: none;
    }
  }
  .ant-tabs-nav-list{
    width: 100%;
    .ant-tabs-tab{
      margin: 0;
      width: 50%;
      justify-content: center;
      padding: 20px 0;
      font-size: 20px;
      color: #898D8F;
      &:hover{
        color: $autoFontColor;
      }
    }
    .ant-tabs-ink-bar{
      width: 50%;
      background-color: #898D8F;
      height: 1px;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
      color: $autoFontColor;
    }
  }
  .ant-form-item{
    margin-top: 8px;
  }
  .forget-password{
    text-align: right;
    font-size: 16px;
    font-family: PingFang;
    font-weight: 500;
    color: #5767FA;
    margin: 12px 0;
    // cursor: pointer;
  }
  .login-btn{
    width: 100%;
    height: 60px;
    background: #5767FA;
    border-radius: 8px;
    line-height: 56px;
    text-align: center;
    font-size: 22px;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0;
    margin-top: 20px;
  }
  .ant-btn[disabled]{
    color: rgba(0,0,0,.25) !important;
    background: #f5f5f5;
    border-color: #f1e9e9 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .login-problem, .register-problem{
    font-size: 12px;
    font-weight: 400;
    color: #7A7A7A;
    .link{
      cursor: pointer;
      color: #007AFE;
    }
  }
  .register-problem{
    padding: 12px 33px 0 38px;
    @include media-breakpoint-down(lg) {
      .ant-checkbox-wrapper{
        & > span:last-child{
          font-size: 12px;
        }
      }
    }
  }
  .ant-input, .ant-input-affix-wrapper, .ant-select-selector{
    // border-color: transparent !important;
  }
  .ant-input{
    font-size: 14px;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input{
    border-color: #ff4d4f !important;
  }
}

