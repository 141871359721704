@import '@/components/commonStyle/common.scss';

.formReset {
  .ant-form-item-label {
    width: 90px;
  }

  .display-flex {
    display: flex;
    align-items: flex-start;
  }

  .flex1 {
    flex: 1;
    padding-left: 24px;
    box-sizing: border-box;
  }
}