
@import '@/components/commonStyle/common.scss';

@mixin mixin-btn($color) {
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: $color;
  width: 62px;
  height: 24px;
  line-height: 22px;
  border: 1px solid $color;
  border-radius: 11px;
  cursor: pointer;
}

.btnIcon{
  width: 11px;
  margin-right: 5px;
  transform: translateY(-1px);
}

.DefaultBtn{
  @include mixin-btn(#d9d9d9);
  transition: all 0.3s;
  color: #04141E;
  &:hover{
    opacity: 0.8;
  }
}

.deleteBtn{
  @include mixin-btn(#fb9131);
  transition: all 0.3s;
  .btnIcon{
    margin-top: -2px;
  }
  &:hover{
    opacity: 0.8;
  }
}
.copyBtn{
  @include mixin-btn(#5f7481);
  transition: all 0.3s;
  .btnIcon{
    margin-top: -2px;
  }
  &:hover{
    opacity: 0.8;
  }
}

.editBtn{
  @include mixin-btn(#3fb6dc);
  transition: all 0.3s;
  .btnIcon{
    margin-top: -1px;
  }
  &:hover{
    opacity: 0.8;
  }
}

.uploadBtn{
  @include mixin-btn(#4291FB);
  transition: all 0.3s;
  &:hover{
    opacity: 0.8;
  }
}

.viewBtn{
  @include mixin-btn(#13C2C2);
  transition: all 0.3s;
  &:hover{
    opacity: 0.8;
  }
}

.hideBtn{
  @include mixin-btn(#248AFF);
  transition: all 0.3s;
  :global{
    .anticon{
      // margin-top: 2.5px;
      font-size: 12px;
      margin-right: 3px;
    }
  }
  &:hover{
    opacity: 0.8;
  }
}

.czBtn{
  @include mixin-btn(#5767FA);
  transition: all 0.3s;
  :global{
    .anticon{
      // margin-top: 2.5px;
      font-size: 12px;
      margin-right: 3px;
    }
  }
  &:hover{
    opacity: 0.8;
  }
}

.exportButton{
  width: 86px;
  height: 32px;
  background: #5767FA;
  border-radius: 16px;
  font-size: 14px !important;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}

.searchBtn{
  width: 86px;
  height: 32px;
  background: #5767FA;
  border-radius: 16px;
  font-size: 14px !important;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  border: none;
  :global{
    .anticon{
      margin-top: 2.5px;
      font-size: 15px;
    }
    .anticon+span{
      margin-left: 5px;
    }
  }
  &:focus{
    background: #5767FA;
    color: #FFFFFF;
  }
  &:hover{
    background: #5767FA;
    color: #FFFFFF;
    opacity: 0.8;
  }
}

.resetBtn{
  width: 86px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDCDCD;
  border-radius: 16px;
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #8A8A8A;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  :global{
    .anticon{
      // margin-top: 2.5px;
      font-size: 14px;
      transform: rotate(180deg);
      margin-right: 5px;
    }
  }
}

.downloadBtn{
  color: #0048FF;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.7;
  .downloadIcon{
    width: 11px;
    height: 11px;
    margin-right: 4px;
    margin-top: -2.5px;
  }
  &:hover{
    opacity: 1;
  }
}

.consultant{
  border: 1px solid #0060FF;
  background: #ffff;
  color: #0060FF;
  margin-left: 18px;
  line-height: 42px;
  width: 126px;
  height: 44px;
  border-radius: 22px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  .consultIcon {
    width: 16px;
    margin-right: 8px;
    margin-top: -2.5px;
  }
  &:hover{
    .consultIcon{
      animation: shake 0.5s;
    }
  }
}

.titleWrap{
  // margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .title{
    @include form-title;
  }
  .subtitle{
    @include form-subtitle;
  }
  .addBtn{
    // border: 1px solid #5767FA;
    background-color: #5767FA;
    border-radius: 16px;
    font-size: 14px;
    color: #fff;
    border: none;
    transition: all 0.3s;
    .addBtnIcon{
      transform: translate(4px, 1px);
    }
    &:hover{
      opacity: 0.8;
    }
  }
}
