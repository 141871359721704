@import '@/components/commonStyle/common.scss';

.modalWrap {
  @include media-breakpoint-down(sm) {
    width: calc(100vw - 40px) !important;
  }

  :global {
    .ant-modal-content {
      border-radius: 10px;
    }

    .ant-modal-body {
      padding: 0;
      overflow: hidden;
      background: #F4F7FD;
      border-radius: 10px;
      position: relative;
    }
  }

  .icon {
    // width: 16px;
    // height: 16px;
    display: block;
    margin: 15px 15px 0 auto;
    cursor: pointer;
  }

  .container {
    padding: 2px 44px 53px;
    @include media-breakpoint-down(lg) {
      padding: 2px 22px 33px;
    }
  }
}