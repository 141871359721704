@import '@/components/commonStyle/common.scss';

.detailWarp{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.detailLi{
		width: calc((100% - 20px) / 2);
		margin-right: 20px;
		margin-bottom: 15px;
		display: flex;
		// align-items: center;
		.label{
			width: 100px;
			text-align: right;
			margin-right: 5px;
		}
		.value{
			flex: 1;
		}
		&:nth-child(2n){
			margin-right: 0;
		}
	}
	.rowLi{
		width: 100%;
	}
}